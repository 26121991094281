/************************************************
* File: Home.js                                 *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components contains user    *
*               profile.                        *
*************************************************/

import React, { useContext, useEffect, useState} from 'react'
import Navigation from './Navigation'
import Player from './Player'
import WrongAnswers from './WrongAnswers';
import APIService from '../APIService';
import { UserContext } from './UserContext';
import {useCookies} from 'react-cookie';
import "../css/Home.css"
import "../css/common.css"
import text from '../text.json'
import close from  '../icons/close-lg-svgrepo-com.svg';
import edit from '../icons/edit-svgrepo-com.svg'
import settings from '../icons/settings-svgrepo-com.svg'
import logout from '../icons/logout-svgrepo-com.svg'


function Home() {
  const [token] = useState(() => localStorage.getItem("token") || "" ); 
  const [user_id ] = useState(() => localStorage.getItem("id") || 0 ); 
  const [cookies, setCookie, removeCookie] = useCookies(['mytoken'])
  const [username ] = useState(() => localStorage.getItem("username") || "" ); 
  const [pic, setPic] = useState(null)
  const [isEditing,SetIsEditing] = useState(false)
  const [selectPic,setSelectPic] = useState(null)
  const [disabled,setDisabled] = useState(true)
  const [profilePic, setProfilePic ] = useState(() => localStorage.getItem("profile_pic") || null ); 
  const [wrongAnswerList,SetWrongAnswerList] = useState(null)
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("username")
    localStorage.removeItem("id")
    localStorage.removeItem("completed_training")
    localStorage.removeItem("win_fights")
    localStorage.removeItem("correct_answer")
    localStorage.removeItem("correct_answer")
    localStorage.removeItem("token")
    localStorage.removeItem("profile_pic")
    removeCookie('mytoken');
  };

 const handleSelectPic = (picName) => {
    setDisabled(false)
   
  }

  const handleSavePic = () => {
    const formData = new FormData();
    formData.append('pic', pic);
    const body =  {
      "pic": formData
    };
    APIService.UpdatePlayerPic(token, user_id, body)
    .catch(error => console.log(error))
   }
  
   // Set profile picture
   useEffect(() => {
    if (profilePic ){
            let pic = document.querySelector(".profile-pictures")
            if (pic){
              pic.style.backgroundImage = `url(${profilePic})`;
            }
          }
  }, []);

  // Get profile picture
  useEffect(() => {
    if (profilePic === null ){
        APIService.PlayerPic(token,user_id)
          .then(resp => {
            let pic = document.querySelector(".profile-pictures")
            if (resp.length > 0 && pic){
              localStorage.setItem("profile_pic", resp);
              setProfilePic(resp)
              pic.style.backgroundImage = `url(${resp})`;
            }
            
          })
          .catch(error => console.log(error))
          }
  }, []);
  
  // Get wrong answers
  useEffect(() => {
    if (wrongAnswerList === null ){
        APIService.WrongAnswers(token,user_id)
          .then(resp => {
           SetWrongAnswerList(resp)
          })
          .catch(error => console.log(error))
          }
  }, []);

  return (
    
    <div className='main-wrap'>
      {isEditing ? 
      <>
       <button className="left-top-corner" onClick={() => {SetIsEditing(false),  setDisabled(true)}}>
          <img className="nav-icon " src={close} alt="Ikona křížku" />
        </button>
        <h1 className='PageName'>Změna avatara</h1>
        
         <div className='profile_pic-grid2x2'>
                  <div id="pic1" className={`profile_pic-item ${pic === 'avatar1.png' ? 'selected_pic' : '' } `} onClick={() => handleSelectPic("avatar1.png")}>
                  <img  alt="Avatar1" src="https://poznejrostlinymedia.s3.eu-central-1.amazonaws.com/avatar1.png" />
                  </div>
                  <div id="pic2" className={`profile_pic-item ${pic === 'avatar2.png' ? 'selected_pic' : '' } `} onClick={() => handleSelectPic("avatar2.png")}>
                  <img alt="Avatar2" src="https://poznejrostlinymedia.s3.eu-central-1.amazonaws.com/avatar2.png" />
                  </div>
                  <div id="pic3"className={`profile_pic-item ${pic === 'avatar3.png' ? 'selected_pic' : '' } `} onClick={() => handleSelectPic("avatar3.png")}>
                  <img alt="Avatar3" src="https://poznejrostlinymedia.s3.eu-central-1.amazonaws.com/avatar3.png" />
                  </div>
                  <div id="pic4"className={`profile_pic-item ${pic === 'avatar4.png' ? 'selected_pic' : '' } `} onClick={() => handleSelectPic("avatar4.png")}>
                  <img alt="Avatar4" src="https://poznejrostlinymedia.s3.eu-central-1.amazonaws.com/avatar4.png" />
                  </div>
                  
                </div>
                
        <input type="file" name="image" onChange={(event) => setPic(event.target.files[0])}/>
       
     
                <div className='center'>
                <button id="save-btn" disabled={disabled} onClick={handleSavePic}className='btn common_btn blue_btn w85'>ULOŽIT</button> 
                </div>
      
      </>
      : 
      <>
       <div className="wrap">

<       div className="dropdown">
            
                <img className="ic" src={logout} onClick={handleLogout} alt="Ikona ozubeného kola" />
            
          </div>

          <div className="edit"  ><img className="edit-icon" onClick={()=>SetIsEditing(true)} src={edit} alt="Ikona editování"/></div>
          <div className="prof_n_name">
            <div className="profile-pictures"></div>
            <h3>{username}</h3>
        </div>
      </div>
       <Player></Player>
       {wrongAnswerList && <WrongAnswers List={wrongAnswerList}></WrongAnswers>}
       <Navigation page="profile"/>
       </>
      }
    </div>
  )
}

export default Home