/**************************************************
* File: UserContext.js                            *
* Author: Marketa Nedelova (xnedel09)             *
* Creation-date:  04/03/2023                      *
* Description: this components should create      *
*              user context for sharing variables *
***************************************************/

import { createContext, useState, useEffect, useContext } from "react";
import {useCookies} from 'react-cookie';

const UserContext = createContext();
export  default  UserContext;


export const  UserContextProvider = ({children}) => {
    let [username] = useState(() => localStorage.getItem("username") || "" );
    let [token] = useState(() => localStorage.getItem("token") || "" ); 
    let value = {
        username:username,
        token:token,
       
 };
  
    return (
      <UserContext.Provider value={value}>
        {children}
      </UserContext.Provider>
    );

  };
  