/************************************************
* File: TrainingQuiz.js                         *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components shows quiz.      *
*************************************************/

import React, {useState, useEffect, useContext}  from 'react'
import {Link, useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import APIService from '../APIService';
import PlantDetail from './PlantDetail';
import "../css/common.css"
import '../css/Fight.css';
import close from  '../icons/close-lg-svgrepo-com.svg';
import correct from  '../icons/tick-mark-icon.svg';
import incorrect from  '../icons/incorrect-icon.svg';
import BeatLoader from "react-spinners/BeatLoader";
import text from '../text.json';
import UserContext from './UserContext'

function TrainingQuiz() {
    
    let {token}  = useContext(UserContext); //[token, setToken] = useCookies(['mytoken']);
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const c_id = params.get('c_id'); 
    const [showResult,setShowResult] = useState(false);
    const [questionCounter, setQuestionCounter] = useState(0);
    const [questionCount, setQuestionCount] = params.get('cnt'); //useState(cnt);
    const [score, setScore] = useState(0);
    const [result, setResult] = useState("");
    const [completed_training, setCompleted_training]  = useState(() => localStorage.getItem("completed_training") || 0 );
    const [correct_answer, setCorrect_answer]  = useState(() => localStorage.getItem("correct_answer") || 0 );
    const [id ] = useState(() => localStorage.getItem("id") || "" ); // it is a user id
    let navigate = useNavigate()
    const [questions, setQuestions] = useState(null);

  
    useEffect(() => {
          APIService.TrainingQuestions(token,c_id,questionCount) 
          .then(
              resp => setQuestions(resp)
          )
          .catch(error => console.log(error))
       }, []);

    const handleAnswerOption = (answer) => {
      if (questions[questionCounter].correct_answer === answer){
        setResult("Správně");
        setScore(score + 1 );
      }
      else{
        setResult("Špatně");
       
        if (parseInt(questions[questionCounter].type) === 0){
           const body = {
            "plant_name": questions[questionCounter].plant_name,
            "photo_url": questions[questionCounter].photo_url,
            "wrong_answer": answer ? "ANO JE" : "NE NENÍ",
            "correct_answer": questions[questionCounter].correct_answer ? "ANO JE" : "NE NENÍ",
            "type": parseInt(questions[questionCounter].type),
            "user": id
          }
          // Create and save wrong answer
          APIService.CreateWrongAnswer(token,body) 
          .catch(error => console.log(error))
        }

        if (parseInt(questions[questionCounter].type) === 1){
           const  body = {
              "photo_url": questions[questionCounter].photo_url,
              "wrong_answer": answer,
              "correct_answer": questions[questionCounter].correct_answer,
              "type": parseInt(questions[questionCounter].type),
              "user": id
            }

            // Create and save wrong answer
          APIService.CreateWrongAnswer(token,body) 
          .catch(error => console.log(error))
          }

          if (parseInt(questions[questionCounter].type) === 2){
            const  body = {
               "plant_name": questions[questionCounter].plant_name,
               "wrong_answer": answer,
               "correct_answer": questions[questionCounter].correct_answer,
               "type": parseInt(questions[questionCounter].type),
               "user": id
             }
 
             // Create and save wrong answer
           APIService.CreateWrongAnswer(token,body) 
           .catch(error => console.log(error))
           }
        
       
      } 
      setShowResult(true);
    }
    
    const handleNextQuestion = () => {
      setShowResult(false)
       setQuestionCounter(questionCounter + 1)
      if (parseInt(questionCount)  === parseInt(questionCounter) + 1) {
        
        setResult(''); 
        statisticsUpdate(token,score);

      }
      
    }

    const statisticsUpdate = (token,score) => {
        const answ = parseInt(correct_answer) + parseInt(score)
        setCorrect_answer(answ)
        const training = parseInt(completed_training) + 1;
        setCompleted_training(training)
        const body =  {
          "correct_answer": answ,
          "completed_training": training
        };

        APIService.UpdatePlayerStats(token, id, body)
        .catch(error => console.log(error))

    }


    const handleCloseTraining = () => {
     navigate('/training')
     }

  return (
    <div className='main-wrap'> 
      { parseInt(questionCount)  === parseInt(questionCounter)? 
      (
       <div>
      <div><h3 className='score'>Vaše celkové skóre:{score}/{questionCount}</h3></div>
       <div className='flex-wrap'> <Link className='common_btn categorybtn wide_btn' to="/training">TRÉNINK</Link></div>
       <div className='flex-wrap'> <Link className='common_btn categorybtn wide_btn' to="/">DOMŮ</Link></div>
       
       </div>
        ) :  (   showResult  ? (
          <div>
            <div className={`result_div  ${result === "Správně" ? "correct_result" : "wrong_result"}`}>
            <img className="big-icon " src={result === "Správně" ? correct : incorrect} alt={result === "Správně" ? "Ikona fajfky" : "Ikona křížku"} />
             <h2 id='result' >{result}</h2>
             </div>
         <PlantDetail plant_id={questions[questionCounter].plant_id}></PlantDetail>
          <div className='flex-wrap'>
          {questionCount === questionCounter?
           <button  className='common_btn categorybtn wide_btn' onClick={handleNextQuestion}>Konec</button>
           : 
         <button  className='common_btn categorybtn wide_btn' onClick={handleNextQuestion}>Další</button>}
          </div>
        </div>
      ) : (
              
              <>
              {/* --------------------------- TRAINING QUESTIONS ----------------------------- */}
              <div className="left-top-corner" onClick={handleCloseTraining}>
              <img className="nav-icon " src={close} alt="Ikona křížku" />
              </div>
              
                {questions && questions.length > 0 ? 
                <>
                {/* ------------------------- QUESTION TYPE 0 ---------------------------------- */}
                    {parseInt(questions[questionCounter].type) === 0 && (
                  <>
                <div className='question-div'>
                <img id="flower_photo one_flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url} />
                    </div>
                    <h3 className='question-style margin '>Je tato rostlina {questions[questionCounter].plant_name}?</h3>
            
              <div className='answer-style'>
                <button className='common_btn white_btn big-padding' onClick={ () => handleAnswerOption(true)}>{text.fight.yes_btn}</button>
                <button className='common_btn white_btn big-padding' onClick={() => handleAnswerOption(false)}>{text.fight.no_btn}</button>
              </div>
              </>
            
              )
              } 
               {/* ------------------------- QUESTION TYPE 1 ---------------------------------- */}
               {parseInt(questions[questionCounter].type) === 1 && (
                  <>
               <div className='question-div'>

              <img id="one_flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url} />

              </div>
              <h3 className='question-style'>Rostlina na fotografii je:</h3>
              <div className='btn-gr'>
                <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name1)}>{questions[questionCounter].plant_name1}</button>
                <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name2)}>{questions[questionCounter].plant_name2}</button>
                <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name3)}>{questions[questionCounter].plant_name3}</button>
                <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name4)}>{questions[questionCounter].plant_name4}</button>
              </div>
              </>
            
              )
              } 
               {/* ------------------------- QUESTION TYPE 2 ---------------------------------- */}
               {parseInt(questions[questionCounter].type) === 2 && (
                  <>
                  <h3 className='question-style margin'>Na které fotografii je {questions[questionCounter].plant_name}?</h3>
                  <div className='center_div'> 
                <div className=' flex-grid2x2'>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url1)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url1} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url2)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url2} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url3)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url3} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url4)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url4} />
                  </div>
                  </div>
                </div>
              </>
            
              )
              } 
              <div className='num_of_questions-div'>
              <p className='num_of_questions'>{questionCounter+1}/{questionCount}</p>
              </div>
              </>
              : 
              <div className='center'>
              <BeatLoader  color="#373F51" />
            </div>
              }
              </>
            
            )
      )}
</div>
      )
   
}

export default TrainingQuiz