/************************************************
* File: Fight.js                                *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components is about         *
*              fighting with each other.        *
*************************************************/

import React, {useState, useEffect, useCallback } from 'react';
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import {Link} from 'react-router-dom';
import Navigation from './Navigation'
import text from '../text.json';

import '../css/common.css';
import '../css/Player.css';
import '../css/Fight.css';

import correct from  '../icons/tick-mark-icon.svg';
import incorrect from  '../icons/incorrect-icon.svg';
import right from  '../icons/triangle-right-svgrepo-com.svg';
import bell from  '../icons/notification-alarm-buzzer-icon.svg';
import close from  '../icons/close-lg-svgrepo-com.svg';
import depressed from '../icons/depressed-icon.svg';
import success from '../icons/success-icon.svg';
import random from '../icons/random-svgrepo-com.svg';
import gong from '../icons/gong-svgrepo-com.svg';

import win from "../images/win.jpg"
import cat from "../images/cat1.jpg"
import PlantDetail from './PlantDetail';
import BeatLoader from "react-spinners/BeatLoader";

let previousHeight = window.innerHeight;

window.addEventListener('resize', () => {
  const currentHeight = window.innerHeight;
  let stats_div = document.querySelector(".stats-wrap")
  if (stats_div){
    if (previousHeight > currentHeight) {
      stats_div.style.display = "none";
    } else {
      stats_div.style.display = "flex";
    }
  }
 
  previousHeight = currentHeight;
});

function Fight() {
  const [token] = useState(() => localStorage.getItem("token") || "" ); 
  const [user_id ] = useState(() => localStorage.getItem("id") || "" ); 
  const [rival, setRival] = useState([]);
  const [userList, setUserList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const startFight = document.getElementById("start_fight");
  const [rivalExist, setRivalExist] = useState(false);
  const [username ] = useState(() => localStorage.getItem("username") || "" );
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [categoriesAreLoaded, setCategoriesAreLoaded] = useState(false);
  const  [isFight, setIsFight] = useState(false);
  const  [rivalIsFighting, setRivalIsFighting] = useState(false);
  const  [fight, setFight] = useState([]);
  const  [fightInvitation, setFightInvitation] = useState([]); // contains notice FightNotice
  const  [fightInvitationIndex, setFightInvitationIndex] = useState(0);
  const  [questions, setQuestions] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const  [showQuestions, setShowQuesitons] = useState(false);
  const [score, setScore] = useState(0);
  const [questionCounter, setQuestionCounter] = useState(0);
  const [questionCount, setQuestionCount] = useState(7);
  const [result, setResult] = useState("");
  const [showResult,setShowResult] = useState(false);
  const [correct_answer, setCorrect_answer]  = useState(() => localStorage.getItem("correct_answer") || 0 ); 
  const [winFights, setWinFights]  = useState(() => localStorage.getItem("win_fights") || 0 );
  const [otherUserName, setOtherUserName] = useState("");
  const [selectedUser, setSelectedUser] = useState (null)
  const  [informationScreen, setInformationScreen] = useState(true);
  const [maxLengthInput] = useState(40); 
  const  [invitationLoading, setInvitationLoading] = useState(true);
  const  [winningsNumber, setWinningsNumber] = useState("?");
  const  [lossesNumber, setLossesNumber] = useState("?");
  const  [disabled, setDisabled] = useState(true);
  const  [errorMessage,  setErrorMessage] = useState("");
  const [fightTimeStart, setfightTimeStart] = useState(null);
  const [loading, setLoading] = useState(false);
  const  [rivalName,  setRivalName] = useState("");


  useEffect(() => {
    if (!categoriesAreLoaded){
      APIService.Categories(token)
      .then(resp => {    
      setCategoryList(resp);
      
    setCategoriesAreLoaded(true);
  })
    .catch(error => console.log(error))
  }

  setInvitationLoading(true)
    APIService.GetFight(token,user_id)
    .then(resp => {setFightInvitation(resp)
      if(resp.length !== 0){
        const notice = document.getElementById("notice")
        if (notice){
          notice.style.backgroundColor = "#373F51"
        }
      }
   })
    .catch(error => console.log(error))
    
   
    APIService.FightNotice(token,user_id)
    .then(resp => {
      if(resp.length !== 0){
        setFightInvitation(prevState => prevState.concat(resp))
        const notice = document.getElementById("notice")
        if (notice){
          notice.style.backgroundColor = "#373F51"
        }
      }
      setInvitationLoading(false)
   })
    .catch(setInvitationLoading(false))
    

  }, []);

  useEffect(() => {
  if (categoryList.length > 0){
    setSelectedCategoryId(categoryList[0].id) 
    
  }
}, [categoryList]);

  useEffect(() => {
      if (inputValue !== '' && !rivalExist  ){ 
      APIService.RivalSearch(token, inputValue)
      .then(resp => setUserList(resp))
      .catch(error => console.log(error))
      }
      else{
        setUserList([]);
        
      
      }
  
    }, [inputValue]);

  useEffect(() => {
  if (inputValue !== ''  ){ 
      APIService.UserExists( inputValue) 
      .then(resp => {
        if( Object.keys(resp).length !== 0 && username !== resp.username){
            setRival(resp)
            setRivalExist(true)
            setDisabled(false);
            APIService.FightStats(token,user_id,resp.id) 
            .then(resp => {
             if( Object.keys(resp).length !== 0 ){
                if ( parseInt(resp.u1) === parseInt(user_id)){
                  setWinningsNumber(resp.u1_winnings)
                  setLossesNumber(resp.u2_winnings)
                }
                else{
                  setWinningsNumber(resp.u2_winnings)
                  setLossesNumber(resp.u1_winnings)
                }
              }
              else{
                // fight stats doesn't exist 
                setWinningsNumber(0)
                setLossesNumber(0)
              }
   })
    .catch(error => console.log(error))
       }
       else{
        setRivalExist(false)
        setDisabled(true);
        setWinningsNumber("?")
        setLossesNumber("?")
       }
      }) 
      .catch(error => setRivalExist(false));
   }
    else{
      setRivalExist(false)
    }
  }, [inputValue])
  

  const handleErrorMessage = () => {
     setErrorMessage(null)
    APIService.DeleteFight(token, fight.id)
    .then( setFight([]))
    .catch(error => console.log(error));
  }

  const handleRandomRival = () => {
    APIService.RandomRival(token)
    .then(resp => {
      setInputValue(resp.username);

      setRivalExist(true);
      setRival(resp);
      
    })
    .catch(error => console.log(error))
  }

  /*
  const handleCreateFight = () => {
    setLoading(true)
    const body =  {
      "challenger": user_id,
      "rival": rival.id
    };
    APIService.CreateFight(token, body)
    .then(resp => {
      setFight(resp)
      APIService.CreateFightQuestions(token, selectedCategoryId, resp.id)
      .then(resp => {
        if (resp && resp.length > 0){
          setQuestions(resp)
          setQuestionCount(resp.length)
          setIsFight(true);
        }else{
          
          setErrorMessage("Při získávání otázek souboje došlo k neočekávané chybě.")
        }})
      .catch(error => {
        console.log(error)
        setErrorMessage("Při získávání otázek souboje došlo k neočekávané chybě.")})
      .finally(() => {
          setLoading(false);
        })
  })
    .catch(error => {
      console.log(error);
     setErrorMessage("Při tvorbě souboje došlo k neočekávané chybě.")})
    
}
*/

const handleCreateFight = async () => {
  setLoading(true);
  const body = {
    "challenger": user_id,
    "rival": rival.id
  };

  try {
    const fightResponse = await APIService.CreateFight(token, body);
    setFight(fightResponse);

    const questionsResponse = await APIService.CreateFightQuestions(token, selectedCategoryId, fightResponse.id);
    console.log(questionsResponse)
    if (questionsResponse && questionsResponse.length > 0) {
      setQuestions(questionsResponse);
      setQuestionCount(questionsResponse.length);
      setIsFight(true);
    } else {
      throw new Error("Nebyly obdrženy žádné otázky souboje.");
    }
  } catch (error) {
    console.error("Chyba při vytváření souboje nebo otázek:", error);
    setErrorMessage("Při tvorbě souboje došlo k chybě.");
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {

      if( questions.hasOwnProperty([questionCounter.toString()])  ){
        setShowQuesitons(true)
      }
      
 }, [questions]);


  useEffect(() => {
    if (rival.length > 0){
      setInputValue(rival.username);
      setRivalExist(true);
    }
  }, [rival])

  const handleCloseFight = () => {
   APIService.DeleteFight(token, fight.id)
    .then(setIsFight(false), setFight([]))
    .catch(error => console.log(error));
    setIsFight(false)
  }
  

  useEffect(() => {
    if (fightInvitation.length > 0){
      let id
      if (fightInvitation[fightInvitationIndex].winner){
        id = fightInvitation[fightInvitationIndex].rival
      }
      else{
        id = fightInvitation[fightInvitationIndex].challenger
      }
      APIService.Username(token, id)
      .then(resp => {
        setOtherUserName(resp.username)
        
      }) 
      .catch(error => console.log(error));
    }
    else{
      const notice = document.getElementById("notice")
        if (notice){
          notice.style.backgroundColor = "#fff"
        }
    }
  }, [fightInvitation, fightInvitationIndex])
 

const handleCategoryChange = (event) =>{
  setSelectedCategoryId(event.target.value);
}

const handleGiveUp = () =>{
  console.log(fightInvitation[fightInvitationIndex].challenger)
  const body =  {
    "winner": fightInvitation[fightInvitationIndex].challenger,
    "rival_correct_answers": 0,
    "rival_check_result": true
  };
  APIService.UpdateFight(token, fightInvitation[fightInvitationIndex].id, body)
  .then(resp => console.log(resp))
  .catch(error => console.log(error))

  // After give up remove invitation from array
  let new_fight_invitation_array = fightInvitation
  new_fight_invitation_array =  new_fight_invitation_array.filter(function (fight_invitation) {
    return fight_invitation.id !== fightInvitation[fightInvitationIndex].id
  })

  setFightInvitation(new_fight_invitation_array)
  setVisibility()

  if(rivalIsFighting){
    setIsFight(false);
    setFight([]);
    setRivalIsFighting(false);
  }
}

const handleFight = () => {
  console.log((fightInvitation[fightInvitationIndex].id))

  APIService.FightQuestionsForRival(token, fightInvitation[fightInvitationIndex].id)
  .then(resp => {
    setQuestions(resp);
    console.log(resp); 
    setFight(fightInvitation[fightInvitationIndex]); 
    setIsFight(true)
    setRivalIsFighting(true)
  })
  .catch(error => console.log(error))
  //
}

const handleAnswerOption = (answer) => {
  
  if (questions[questionCounter].correct_answer === answer){
    setResult("SPRÁVNĚ");
    setScore(score + 1 );
  }
  else{
    setResult("ŠPATNĚ");
    if (parseInt(questions[questionCounter].type) === 0){
      const body = {
       "plant_name": questions[questionCounter].plant_name,
       "photo_url": questions[questionCounter].photo_url,
       "wrong_answer": answer ? "ANO JE" : "NE NENÍ",
       "correct_answer": questions[questionCounter].correct_answer ? "ANO JE" : "NE NENÍ",
       "type": parseInt(questions[questionCounter].type),
       "user": user_id
     }
     // Create and save wrong answer
    
     APIService.CreateWrongAnswer(token,body) 
     .catch(error => console.log(error))
   }

   if (parseInt(questions[questionCounter].type) === 1){
      const  body = {
         "photo_url": questions[questionCounter].photo_url,
         "wrong_answer": answer,
         "correct_answer": questions[questionCounter].correct_answer,
         "type": parseInt(questions[questionCounter].type),
         "user": user_id
       }

       // Create and save wrong answer
     APIService.CreateWrongAnswer(token,body) 
     .catch(error => console.log(error))
     }

     if (parseInt(questions[questionCounter].type) === 2){
       const  body = {
          "plant_name": questions[questionCounter].plant_name,
          "wrong_answer": answer,
          "correct_answer": questions[questionCounter].correct_answer,
          "type": parseInt(questions[questionCounter].type),
          "user": user_id
        }

        // Create and save wrong answer
      APIService.CreateWrongAnswer(token,body) 
      .catch(error => console.log(error))
      }
  } 

  setShowResult(true);
}

const handleNextQuestion = () => {
  setShowResult(false)
  setQuestionCounter(questionCounter + 1)
  if (questionCounter  + 1 === questionCount) { 
    setResult(''); 
    let update_winner_cnt = false
    let body = {}
    if (rivalIsFighting){
      let winner;
      if(parseInt(score) > parseInt(fight.challenger_correct_answers)){
        winner = user_id
        update_winner_cnt = true

        handleChangeFightStats(fightInvitation[fightInvitationIndex].challenger, user_id)
      }
      else{
        winner = fightInvitation[fightInvitationIndex].challenger
      }
      console.log((new Date() - fightTimeStart)/1000)
       body =  {
        "rival_correct_answers": score,
        "rival_check_result": true,
        "winner": winner,
        "rival_completion_time_float": (new Date() - fightTimeStart)/1000
      };

      statisticsUpdate(token,score,update_winner_cnt);
    APIService.UpdateFight(token,fightInvitation[fightInvitationIndex].id, body)
    .then(resp => console.log(resp))
    .catch(error => console.log(error))
  
    }
    else{
      console.log((new Date() - fightTimeStart)/1000)
       body =  {
        "challenger_correct_answers": score,
        "challenger_completion_time_float": (new Date() - fightTimeStart)/1000
      };
      statisticsUpdate(token,score,update_winner_cnt);
    APIService.UpdateFight(token, fight.id, body)
    .then(resp => console.log(resp))
    .catch(error => console.log(error))
  
    }
    setFight([]) 
}
}

const statisticsUpdate = (token,score, update_winner_cnt) => {
  const answ = parseInt(correct_answer) + parseInt(score)
  setCorrect_answer(answ)
  
  let body
  if(update_winner_cnt){
    const winnings = parseInt(winFights) + 1
    setWinFights(winnings)
    body =  {
      "correct_answer": answ,
      "win_fights": winnings
    };
  }
  else{
     body =  {
      "correct_answer": answ
    };
  }
  APIService.UpdatePlayerStats(token, user_id, body)
  .then(resp => console.log(resp))
  .catch(error => console.log(error))
  

}
  

const setVisibility = () => {
  const notice = document.getElementById("notice")
  if (notice){
    notice.style.visibility = "hidden"
    setTimeout(function() {
      notice.style.visibility = "visible"
    }, 200);
  }
}

const handleChangeFightStats = (other_user_id, user_win_id) => {
  APIService.FightStats(token,user_id, other_user_id)
        .then(resp => {
          console.log(resp)
          if( Object.keys(resp).length !== 0 ){
            // Fight stats exists -> update
            let body
            let winnings
            if(parseInt(user_win_id) === parseInt(user_id)){
              // User is winner
              
              if( parseInt(user_id) === parseInt(resp.u1)){
                winnings = resp.u1_winnings
                winnings += 1
                body = {
                  "u1_winnings": winnings
                }
                
              }
              else{
                winnings = resp.u2_winnings
                winnings += 1
                body = {
                  "u2_winnings": winnings
                }
                
              }

            console.log("existuje")
             APIService.UpdateFightStats(token,user_id,other_user_id, body)
            .then(resp => console.log(resp))
            .catch(error => console.log(error))
            }       
          }
          else{
            // Fight stats doesn't exists -> create
            let num1 = 0
            let num2 = 1
            if ( parseInt(user_win_id) === parseInt(user_id)){
              num1 = 1
              num2 = 0
            }

            const body = {
              "u1": user_id,
              "u2": other_user_id,
              "u1_winnings": num1,
              "u2_winnings": num2
          }
             
            APIService.CreateFightStats(token, body)
            .then(resp => console.log(resp))
            .catch(error => console.log(error))
            
          }
        })
        .catch(error => console.log(error))
        
}

const handleNextFightInvitation = () => {
    if(fightInvitationIndex + 1 === fightInvitation.length){
     
      setFightInvitationIndex(0)
      console.log(fightInvitationIndex)
    }
    else{
      setFightInvitationIndex(fightInvitationIndex + 1)
      console.log(fightInvitationIndex)
    }
    setVisibility()
   
    };

const  handleOkBtn = (win) => {
  if(win){
    console.log("vyhral")
    if (user_id && winFights){
      const winnings = parseInt(winFights) + 1
      setWinFights(winnings)
    const body =  {
      "win_fights": winnings
    };
    APIService.UpdatePlayerStats(token, user_id, body)
    .then(resp => console.log(resp))
    .catch(error => console.log(error))
    }
   
      handleChangeFightStats(fightInvitation[fightInvitationIndex].rival,fightInvitation[fightInvitationIndex].winner)
   
    }

  // Delete fight
  console.log(fightInvitation[fightInvitationIndex].id)
  APIService.DeleteFight(token, fightInvitation[fightInvitationIndex].id)
  .catch(error => console.log(error));

  // After click on OK remove notice from array
  let new_fight_invitation_array = fightInvitation
  new_fight_invitation_array =  new_fight_invitation_array.filter(function (fight_invitation) {
    return fight_invitation.id !== fightInvitation[fightInvitationIndex].id
  })

  setFightInvitation(new_fight_invitation_array)
  // update player stats wins? 
  setVisibility()
}

  return (
    <div className='main-wrap'>

      { isFight ? ( questionCount === questionCounter ? 
      (
       
        <>
          {/* ------------------------- END OF FIGHT ---------------------------------- */}
          <h3 className='score'>Vaše celkové skóre:{score}/{questionCount}</h3>
        
        {rivalIsFighting && (
          <div>
          <h3 className='score'>Soupeřovo celkové skóre:{fightInvitation[fightInvitationIndex].challenger_correct_answers}/{questionCount}</h3>
          { parseInt(score) > parseInt(fightInvitation[fightInvitationIndex].challenger_correct_answers) 
          &&
           
           <>
           <h3 className='score'>Vyhrál jste!</h3>
           <div className="image_wrap">
            <img src={win} alt="Skupina s pohárem oslavující vítězství"/>
          </div> 
           </>
           }
           { parseInt(score) < parseInt(fightInvitation[fightInvitationIndex].challenger_correct_answers) 
          &&
           <>
           <h3 className='score' >Tento souboj jste bohužel prohrál/a.</h3>
           <p className='score'>Snad vám náladu z prohry zlepší toto koťátko.</p>
           <div className="image_wrap" >
            <img src={cat} alt="Koťátko"/>
          </div>
          </> }
          { parseInt(score) === parseInt(fightInvitation[fightInvitationIndex].challenger_correct_answers) 
          &&
           <>
           <p className='score' >Počet správných odpovědí je shodný. O výsledku bylo rozhodnuto na základě času.</p>
           { fightInvitation[fightInvitationIndex].rival_completion_time_float && 
            fightInvitation[fightInvitationIndex].challenger_completion_time_float &&
             parseFloat(fightInvitation[fightInvitationIndex].rival_completion_time_float) < parseFloat(fightInvitation[fightInvitationIndex].challenger_completion_time_float) ?
             <>
             <h3 className='score'>Vyhrál jste!</h3>
             <div className="image_wrap">
              <img src={win} alt="Skupina s pohárem oslavující vítězství"/>
            </div> 
             </>
              :
            <>
              
              <h3 className='score' >Tento souboj jste bohužel prohrál/a.</h3>
              <p className='score'>Snad vám náladu z prohry zlepší toto koťátko.</p>
              <div className="image_wrap" >
                <img src={cat} alt="Koťátko"/>
              </div>
            </>
            }
         
          </> }
          </div>
          
          )}
        
        
        
          <div className='flex-wrap'> <Link className='common_btn categorybtn wide_btn' to="/">{text.fight.home_btn}</Link></div>
        
        </>
        ) :  ( showResult  ? (
        <>
            {/* ------------------------- CORRECT ANSWER ---------------------------------- */}
            <div className={`result_div  ${result === "SPRÁVNĚ" ? "correct_result" : "wrong_result"}`}>
            <img className="big-icon " src={result === "SPRÁVNĚ" ? correct : incorrect} alt={result === "SPRÁVNĚ" ? "Ikona fajfky" : "Ikona křížku"} />
             <h2 id='result' >{result}</h2>
             </div>
         <PlantDetail plant_id={questions[questionCounter].plant_id}></PlantDetail>
         <div className='flex-wrap'>
          {questionCount === questionCounter ?
           <button  className='common_btn categorybtn wide_btn' onClick={handleNextQuestion}>{text.fight.end_btn}</button>
           : 
         <button  className='common_btn categorybtn wide_btn' onClick={handleNextQuestion}>{text.fight.next_btn}</button>}
          </div>
        </>
      )
        
      : (
        informationScreen ? (
          <div className='w85'> 
           <h1 className="PageName margin-top bold_txt">{rivalIsFighting ? "REAKCE NA VÝZVU" : "ZAHÁJENÍ SOUBOJE"}</h1>  
          <div className='center-heigh150'>
          <img className="dark-icon huge-icon " src={gong} alt="Ikona šipky vpravo" />
         </div>
         <div className='vs'>
          <div>
              <div className="profile-pictures"></div>
              <span className='bold_txt600'>{username}</span>
            </div>
            <span className='bold_txt600'>vs.</span>
            <div>
              <div className="profile-pictures"></div>
              {rivalIsFighting ? <span className='bold_txt600'>{otherUserName}</span> : <span className='bold_txt600'>{rival.username}</span>} 
            </div>
          </div>
          
          {rivalIsFighting ? 
          <ul>
            <li className='bold_txt600'>Zodpovíte stejné otázky jako měl váš soupeř.</li>
            <li className='bold_txt600'>Na konci se dozvíte výsledek.</li>
           </ul>
            :
            <ul>
              <li className='bold_txt600'>Zodpovíte {questionCount} {questionCount > 4 ? "otázek" : "otázky"}.</li>
              <li className='bold_txt600'>Soupeř má na reakci týden.</li>
              <li className='bold_txt600'>Pokud nezareaguje, automaticky prohrává.</li>
              <li className='bold_txt600'>Výsledky budou v Upozornění.</li>
            </ul>
          }
         
          <button className="change_category_btn  w100 bold_txt600 bigger_font" onClick={() => {setInformationScreen(false); setfightTimeStart(new Date())}}>{rivalIsFighting ? "REAGOVAT" : "ZAHÁJIT"}</button>
          </div>) : (
            
               <>  
        {/* ------------------------- QUESTION ---------------------------------- */}
     
      {rivalIsFighting ?
        <div className='content'>
          <button className="left-top-corner" onClick={handleGiveUp}>
          <img className="nav-icon" src={close} alt="Ikona křížku" />
          <p className='bold_txt600'>(VZDÁT SE)</p>
        </button>
        </div>
      : 
      <div className='content'>
          <button className="left-top-corner" onClick={handleCloseFight}>
          <img className="nav-icon " src={close} alt="Ikona křížku" />
        </button>
        </div>
     }
      <>
        {showQuestions && (
  
            <>
             {/* ------------------------- QUESTION TYPE 0 ---------------------------------- */}
            {parseInt(questions[questionCounter].type) === 0 && (
               <>
             <div className='question-div'>
             <img id="flower_photo one_flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url} />
                </div>
                <h3 className='question-style margin'>Je tato rostlina {questions[questionCounter].plant_name}?</h3>
        
           <div className='answer-style'>
             <button className='common_btn white_btn big-padding' onClick={ () => handleAnswerOption(true)}>{text.fight.yes_btn}</button>
             <button className='common_btn white_btn big-padding' onClick={() => handleAnswerOption(false)}>{text.fight.no_btn}</button>
           </div>
           </>
        
           )
           } 
            {/* ------------------------- QUESTION TYPE 1 ---------------------------------- */}
            {parseInt(questions[questionCounter].type) === 1 && (
              <>
                <div className='question-div'>

                  <img id="one_flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url} />
                 
                </div>
                <h3 className='question-style'>Rostlina na fotografii je:</h3>
                <div className='btn-gr'>
                    <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name1)}>{questions[questionCounter].plant_name1}</button>
                    <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name2)}>{questions[questionCounter].plant_name2}</button>
                    <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name3)}>{questions[questionCounter].plant_name3}</button>
                    <button className='common_btn white_btn wide_btn medium-padding' onClick={() => handleAnswerOption(questions[questionCounter].plant_name4)}>{questions[questionCounter].plant_name4}</button>
                </div>
           </>
           )
           } 
           {/* ------------------------- QUESTION TYPE 2 ---------------------------------- */}
           {parseInt(questions[questionCounter].type) === 2 && (
              <>
                
                    <h3 className='question-style margin'>Na které fotografii je {questions[questionCounter].plant_name}?</h3>
                
                <div className=' flex-grid2x2'>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url1)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url1} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url2)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url2} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url3)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url3} />
                  </div>
                  <div className='grid-item' onClick={() => handleAnswerOption(questions[questionCounter].photo_url4)}>
                  <img id="flower_photo" alt="Fotografie rostliny" src={questions[questionCounter].photo_url4} />
                  </div>
                </div>
                </>
           
           )
           } 
           <div className='num_of_questions-div'>
            <p className='num_of_questions'>{questionCounter+1}/{questionCount}</p>
            </div>
            </>
        )}
       
      </>
      </>
            
          )
        )
      )) : 
      (
         
        <>
      {/* ------------------------------ NOTICE ---------------------------------------------- */}
      <div className='notice-wrap'>
        <div className="notice-text">
          <img className="dark-icon" src={bell} alt="Ikona zvonu" />
          <h3>{text.fight.notice}</h3>
        </div>
        
        <div id='notice'>
          { fightInvitation.length > 0 ? 
            <div>
              { fightInvitation[fightInvitationIndex].winner ? 
               
              (
                
              <div>
                 { parseInt(fightInvitation[fightInvitationIndex].winner) === parseInt(user_id) ? (
                  <div>
                    <h3> Vyhrál/a jste! V souboji jste dosáhl/a lepšího výsledku než {otherUserName}.  
                   </h3>
                
                    <button className='common_btn white_btn fight_btn' onClick={() => handleOkBtn(true)}>OK</button>
                  </div>
                 ) : (
                  <div>
                     <h3>{otherUserName} vás v souboji porazil/a.</h3>
                  <button className='common_btn white_btn fight_btn' onClick={() =>handleOkBtn(false)}>OK</button>
                  </div>
                 )}
                
              </div>
              ) : 
              
              ( <div>
                 
                 <h3>{otherUserName}  vás vyzývá na souboj</h3>
                 <button className='common_btn white_btn give_up_btn' onClick={handleGiveUp}>VZDÁT SE</button>
                 <button className='common_btn white_btn fight_btn' onClick={handleFight}>SOUPEŘIT</button>
              </div>)
              }
            </div>
             :
             <>
             { invitationLoading &&
             
              <div className='center'>
              <BeatLoader  color="#373F51" />
              </div>
              
             }
             </>
          }
        </div>
        { fightInvitation.length > 1 && 
              (<div onClick={handleNextFightInvitation}>
                
              <img className="dark-icon big-icon right" src={right} alt="Ikona šipky vpravo" />
             </div>)}
      </div>
       {/* ------------------------------ CREATE FIGHT -------------------------------------- */}
      <div className='create_fight-wrap'>
        <h1 className='fight-heading'>{text.fight.heading}</h1>
        <div className = "field">
          <label htmlFor='select_category' className='form-label'>{text.fight.select_category}</label>
          <select value={selectedCategoryId} onChange={handleCategoryChange} id="select_category"
         >
          { categoryList.length > 0 && (
                categoryList.map(category => (
                   <option  key={category.id} id={category.id} value={category.id}
                   >{category.name}</option>
                 ))
                 )
                }
          </select>
       </div>
       <div className = "field ">
       
        <label htmlFor ='select_rival' className ='form-label'>{text.fight.select_rival}</label>
        <div className="choose-rival">
         
              <input type="text" value={inputValue}
               className = "form-control" id="select_rival" 
              placeholder={text.fight.select_rival_placeholder} onChange ={(e) => setInputValue(e.target.value)} maxLength={maxLengthInput}
            ></input>
           
             <button className=' random_user_btn' onClick={handleRandomRival}>
              <img className=" white-icon" src={random} alt="Ikona křížku" />
               {text.fight.random_rival}
             </button>
           
        </div>
        <div className='autocomplete_list'>
          {userList.length > 0 && userList.map((user) =>(
            <div className="autocomplete_user" key={user.id} onClick={() => {
              setRivalExist(true)
              setInputValue(user.username)
              setUserList([])
            }}>
              {user.username}
            </div>
          ))}
        </div>
        
         </div>
      </div>
       {/* ------------------------------ FIGHT STATS -------------------------------------- */}
      <div className='stats-wrap'>
       {errorMessage && errorMessage.length > 0 ? 
       <>
       <p className='error'>{errorMessage}</p>
       <button className='btn common_btn blue_btn' onClick={handleErrorMessage}>OK</button> 
       </>
       :
       <>
       {loading ? 
        <div className='center'>
             <BeatLoader  color="#373F51" />
        </div>
         : 
          <>
          <h1>{text.fight.stats}</h1>
            <div className="win-stats">
                <img className="icon" src={success} alt="Ikona vítěze" />
                <div>{winningsNumber}</div>
                <span>{text.fight.win_fights}</span>
            </div>
            <div className="win-stats train">
                <img className="icon" src={depressed} alt="Ikona poraženého" />
                <div>{lossesNumber}</div>
                <span>{text.fight.lost_fights}</span>
            </div>
          <button className='btn' onClick={handleCreateFight} disabled={disabled} id="start_fight">{text.fight.start_btn}</button> 
          </>
        }
       </>
       }
      </div>
      </>)
     } 
     {!isFight && <Navigation page="fight"/>}
    </div>
  )
}

export default Fight

