/************************************************
* File: Study.js                                *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays         *
*              category names.                  *
*************************************************/

import React, {useState, useEffect, useRef } from 'react'
import CategoryList from './CategoryList'
import APIService from '../APIService'
import {useCookies} from 'react-cookie';
import "../css/common.css";
import text from '../text.json'
import Navigation from './Navigation'
import BeatLoader from "react-spinners/BeatLoader";

function Study() {
  const [token] = useState(() => localStorage.getItem("token") || "" ); 
  const [categories, setCategories] = useState(null)
  const [basicCategories, setBasicCategories] = useState(null)
  const [extendedCategories, setExtendedCategories] = useState(null)
  const [isBasic, setBasic] = useState(true)
  const [study] = useState(true)

  useEffect(() => {
    APIService.BasicCategories(token)
      .then(resp => {
        setCategories(resp)
        setBasicCategories(resp)
        })
        .catch(error => console.log(error))
       
  }, [token])


  const ExtendedBtn = () => {
    setBasic(false);
    if (extendedCategories && extendedCategories.length > 0){
      setCategories(extendedCategories)
    }
    else{
    APIService.ExtendedCategories(token)
    .then(resp =>  {
      setCategories(resp)
      setExtendedCategories(resp)
    })
     .catch(error => console.log(error))
    }
}

const BasicBtn = () => {
  setBasic(true);
  setCategories(basicCategories)
}

  return (
    <div className='main-wrap'>  
        <h1 className='PageName'>{text.study.heading}</h1>
        <div className = "w85">
        {isBasic ? <button onClick={ExtendedBtn} className='change_category_btn'>{text.study.extended_btn}</button>
      : <button onClick={BasicBtn} className='change_category_btn'>{text.study.basic_btn}</button> }
      </div>
      { categories ? <CategoryList categories = {categories} study = {study}/> 
      : 
      <div className='center'>
         <BeatLoader  color="#373F51" />
      </div>}
    
    <Navigation page="study"/>
    </div>
  )
}

export default Study