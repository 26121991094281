/************************************************
* File: WrongAnswers.js                          *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components shows wrong      *
*              answers that player made.        *
*************************************************/

import React, { useEffect, useState } from 'react'
import APIService from '../APIService';
import right from  '../icons/triangle-right-svgrepo-com.svg';
import trash from  '../icons/trash-svgrepo-com.svg';
import "../css/common.css"
import "../css/WrongAnswers.css"


function WrongAnswers(props) {
    const [token] = useState(() => localStorage.getItem("token") || "" );
    const [index, setIndex] = useState(0)
    const [list, setList] = useState(props.List)
    const [selectOption, setSelectOption] = useState(null)
    const [class1, setClass1] = useState("")
    const [result, setResult] = useState("")
    const [showResult, setShowResult] = useState(false)
    const [user_id ] = useState(() => localStorage.getItem("id") || 0 ); 
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * 2));
   

 const handleNext = () => {
    if (index + 1 < list.length ){
        setIndex(index + 1);
    }
    else{
        setIndex(0)
    }
 } 

 


 const handleAnswerOption = (element) =>{
   if (element.target.textContent === list[index]["correct_answer"] || element.target.src === list[index]["correct_answer"] ){
    setResult("SPRÁVNĚ!")

    element.target.classList.add("correct")
   
  
    setTimeout(() => {
        
        setShowResult(false);
        element.target.classList.remove("correct")
        // Delete wrong answer
        APIService.DeleteWrongAnswer(token, user_id, list[index]["id"])
        .catch(error => console.log(error))
        // Update list
        const updated_list = list.filter((item) => item !== list[index])
        if (updated_list.length === 0){
            setList(null)
        }
        else{
            setList(updated_list)
        }
       
        if (updated_list && (index + 1 < updated_list.length) ){
            setIndex(index + 1);

        }
        else{
            setIndex(0)
        }

        setRandomNum(Math.floor(Math.random() * 2)) 
      }, 1500);
   }
   else{
    setResult("ŠPATNĚ!")
    element.target.classList.add("wrong")
    setTimeout(() => {
        handleNext();
        setShowResult(false);
        element.target.classList.remove("wrong")
        setRandomNum(Math.floor(Math.random() * 2)) 
      }, 1500);

     
    
   }


 }

  return (
    
    <div className='wrong_answ_container'>
    <div className='wrong_answ_previous'>
    </div>
    <div className='wrong_answ_content'>
    {list && list.length > 0  && list[index] &&
   
    <>
    {parseInt(list[index]["type"]) === 0 && 
    <>
        <div className='wrong_answ_heading'>
        <h1>{list[index]["plant_name"]}</h1>
        </div>
        <div className='wrong_answ_div'>
            <img id="flower_photo one_flower_photo" alt="Fotografie rostliny" src={list[index]["photo_url"]} />
        </div>
        <div className='wrong_answ_div_btn'>
            <span  className={`wrong_answ_btn `}  onClick={handleAnswerOption}>ANO JE</span>
            <span  className={`wrong_answ_btn `} onClick={ handleAnswerOption}>NE NENÍ</span>
        </div>
    </>}
    {parseInt(list[index]["type"]) === 1 && 
    <>
        <div className='wrong_answ_heading_1'>
            <img id="flower_photo one_flower_photo" alt="Fotografie rostliny" src={list[index]["photo_url"]} />
        </div>
        <div className='wrong_answ_content_1'>
            {randomNum === 1 ? 
            <>
            <span  className={`wrong_answ_btn `}  onClick={handleAnswerOption}>{list[index]["correct_answer"]}</span>
            <span  className={`wrong_answ_btn `} onClick={ handleAnswerOption}>{list[index]["wrong_answer"]}</span>
            </>
            :
            <>
            <span  className={`wrong_answ_btn `} onClick={ handleAnswerOption}>{list[index]["wrong_answer"]}</span>
            <span  className={`wrong_answ_btn `}  onClick={handleAnswerOption}>{list[index]["correct_answer"]}</span>
            </>
            }
           
        </div>
    </>}
    {parseInt(list[index]["type"]) === 2 && 
    <>
        <div className='wrong_answ_heading_2'>
        <h1>{list[index]["plant_name"]}</h1>
        </div>
        <div className='wrong_answ_content_2'>
            {randomNum === 1 ? 
            <>
             <img id="flower_photo" alt="Fotografie rostliny" onClick={handleAnswerOption} src={list[index]["correct_answer"]} />
             <img id="flower_photo" alt="Fotografie rostliny" onClick={handleAnswerOption} src={list[index]["wrong_answer"]} />
            </>
            :
            <>
             <img id="flower_photo" alt="Fotografie rostliny" onClick={handleAnswerOption} src={list[index]["wrong_answer"]} />
             <img id="flower_photo" alt="Fotografie rostliny" onClick={handleAnswerOption} src={list[index]["correct_answer"]} />
            </>
            } 
        </div>
        </>}
    </>
    }
    
    </div>
    <div className='wrong_answ_next'> 
    { list && <h3 id="trash" >{index + 1}/{list.length}</h3>}
    </div>
    </div>
  )
}

export default WrongAnswers