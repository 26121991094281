/************************************************
* File: ChangePassword.js                       *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  09/17/2023                    *
* Description: this components helps with       *
*              changing password                *
*************************************************/

import { Navigate, useParams } from "react-router-dom";
import React, {useState} from 'react'
import arrow from '../icons/arrow-thin-left-icon.svg';
import text from '../text.json';
import '../css/common.css'
import APIService from '../APIService';
import { Link } from 'react-router-dom';

function ChangePassword() {
    const [psw, setPsw] = useState('')
    const [psw2, setPsw2] = useState('')
    const {id} = useParams()
    const [maxLengthInput] = useState(40)
    const [Message, setMessage] = useState('');
    const [msgType, setMsgType] = useState('error')
    const [success, setSuccess] = useState(false)

    const submitForm = () => {
        if (psw !== psw2){
            setMessage("Zadaná hesla se neshodují!");
            setPsw('');
            setPsw2('');
        }
        else if (psw.length < 4) {
            setMessage("Délka vašeho hesla musí obsahovat alespoň 4 znaky.")
        }
        else {
            APIService.ChangePassword(id, {"psw": psw})
            .then(resp => {
                if(resp.bool){
                   setMessage(resp.msg);
                   setMsgType("info")
                   setPsw('');
                   setPsw2('');
                   setSuccess(true)
                }
                else{
                    setMessage(resp.msg);
                    setPsw('');
                    setPsw2('');
                }
                })
            .catch(error => console.log(error))
        }
        
        }

  return (
    <div className='main-wrap'>
         <h1 className='item'>{text['change-psw'].heading}</h1>
         <div className='forgot-psw-box'>
            <div className = "field">
                        <label html='password' className='form-label'>{text['change-psw'].psw}</label>
                        <input type="password" className='form-control' id="password" 
                        placeholder='' value={psw} maxLength={maxLengthInput}
                    onChange = {e => setPsw(e.target.value)}></input>
                    <label html='password2' className='form-label'>{text['change-psw'].psw2}</label>
                        <input type="password" className='form-control' id="password2" 
                        placeholder='' value={psw2} maxLength={maxLengthInput}
                    onChange = {e => setPsw2(e.target.value)}></input>
                    
            </div>
            {Message && <p className={msgType}>{Message}</p>}
            <button type="submit" onClick={submitForm} className = "dark_btn right">{text['change-psw']['send-btn']}</button> 
        </div>
        {success && <div className='flex-wrap'> <Link className='common_btn categorybtn wide_btn' to="/">DOMŮ</Link></div>}
    </div>
   
  )
}

export default ChangePassword