/**************************************************
* File: Leaderboards.js                           *
* Author: Marketa Nedelova (xnedel09)             *
* Creation-date:  04/03/2023                      *
* Description: this components shows leaderboards *
**************************************************/

import React, {useState, useEffect } from 'react'
import {useCookies} from 'react-cookie';
import Navigation from './Navigation'
import APIService from '../APIService'
import "../css/common.css";
import text from '../text.json'
import success from '../icons/success-icon.svg';
import BeatLoader from "react-spinners/BeatLoader";

function Leaderboards() {
  const [loading, setLoading] = useState(false)
  const [token] = useState(() => localStorage.getItem("token") || "" );
  const [username] = useState(() => localStorage.getItem("username") || "" ); 
  const [id ] = useState(() => localStorage.getItem("id") || "" );
  const [leaderboard, setLeaderboard] = useState(null)
  const [order,setOrder ] = useState(1)

  useEffect(() => {
    
   APIService.Leaderboard(token)
   .then(resp => setLeaderboard(resp))
    .catch(error => console.log(error))
  }, [])


  
  return (
    <div  className='main-wrap'>
      <h1 className='PageName'>{text.leaderboards.heading}</h1>
      
      <div className='w85 leaderboard'>
        <div className='leaderboard-text'>
         <p>Pořadí</p>
         <p>|</p>
         <p> Jméno  </p>
         <p>|</p>
         <p>Počet vyhraných soubojů </p>
         <p> </p>
      </div>
      {leaderboard ? leaderboard.map((player, i) => {
        return(
          <div className="leaderboard-row" key={player.id}>
          <div className='black-circle'><span className='bold_txt'>{i+1}</span></div>
          <h3 className='flex-item1'>{player.username}</h3>
          
            <h3 className="flex-item2">{player.win_fights}</h3>
            <img className="icon-black" src={success} alt="Ikona vítěze" />
         </div>
         
        )
      
      }):
      <div className='center'>
           <BeatLoader  color="#373F51" />
      </div>
      }
      
     </div>
     <Navigation page="leaderboards"/>
    </div>
  )
}

export default Leaderboards