/************************************************
* File: Errorpage.js                            *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: the component is needed          *
*              if the page does not exist.      *
*************************************************/

import { useRouteError } from "react-router-dom";
import {Link} from 'react-router-dom';
import notFound from "../images/404.jpg";
import '../css/common.css';
export default function ErrorPage() {

  return (
    <div id="error-page">
      <h1 className="text-center">Jejda!</h1>
      <p className="text-center">Stránka nenalezena.</p>
      <div className="image_wrap" >
            <img src={notFound} alt="404 stránka nenalezena"/>
          </div>
      <div className="text-center">
      <a  href="http://www.freepik.com">Designed by pikisuperstar / Freepik</a>
      </div>
      <div className='flex-wrap'> <Link className='common_btn categorybtn wide_btn' to="/">DOMŮ</Link></div>
    </div>
  );
}