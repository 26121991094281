/************************************************
* File: Login.js                                *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components is for login and *
*               registration of users.          *
*************************************************/

import React, {useState, useEffect, useRef, useContext } from 'react'
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import { useNavigate, Link} from 'react-router-dom';
import eyeSlash from '../icons/eye-closed-svgrepo-com.svg';
import eye from '../icons/eye-open-svgrepo-com.svg';
import "../css/Login.css"
import '../css/common.css'
import BeatLoader from "react-spinners/BeatLoader";

function Login() {
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [maxLengthInput] = useState(40)
    const [username, setUsername] = useState('')
    const [token, setToken] = useCookies(['mytoken'])
    const [isLogin, setLogin] = useState(true)
    const [showPassword, setShowPassword] = useState(false);
    const [isLoginError, setIsLoginError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false)


useEffect(() => {
        if(token['mytoken'] && token.mytoken !== "undefined" ){
            if (username.length > 0){
                localStorage.setItem("token", token['mytoken']);
                localStorage.setItem("username", username);
                APIService.UserId(token.mytoken, username)
                .then(resp =>  {localStorage.setItem("id", resp.id);  navigate('/')})
                 .catch(error => console.log(error))
            }
           
        }
        
    }, [token])

    const loginBtn = async () => {
        setLoading(true)
      APIService.LoginUser({username,password})
        .then(resp => {
            if(resp.token){
                console.log(resp.token)
                 setToken('mytoken',resp.token)
                 setIsLoginError(false)
                 setLoading(false)
                 
            } else{
                setIsLoginError(true);
                setErrorMessage("Přihlášení se nezdařilo. Zkontrolujte prosím své přihlašovací údaje.")
                setLoading(false)
            }
            })
        .catch(error => {setIsLoginError(true);})
    }
  

    const registerBtn = () => {
        setIsLoginError(false)
          /* REGEX from: https://masteringjs.io/tutorials/fundamentals/email-regex Author: MeanIT Software, Inc. Date: 10-27-2022 Integration-Date: 04-23-2023 */
          if (/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi.test(email)) {
            // E-mail is correct
            if(username.length > 0){
                if(password.length > 3){
                    setLoading(true)
                    APIService.UserExists(username)
                    .then((resp) => {
                        if(Object.keys(resp).length !== 0){
                            // User with that name already exists
                            setIsLoginError(true)
                            setErrorMessage("Uživatel s tímto jménem již existuje. Zvolte jiné.")
                        }
                        else{
                           
                            APIService.RegisterUser({username,password,email})
                            .then(() => loginBtn())
                            .catch(error => console.log(error))
                        }})
                    .catch(error => console.log(error))
                    }
                else{
                    setIsLoginError(true)
                    setErrorMessage("Délka vašeho hesla musí obsahovat alespoň 4 znaky.")
                }
            }
            else{
                setIsLoginError(true)
                setErrorMessage("Délka vašeho jména musí obsahovat alespoň 1 znak.")
            }
        }
        else{
            setIsLoginError(true)
            setErrorMessage("Vložili jste neplatný e-mail.")
        }
       
        setLoading(false)
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    }

  return (
    <div className='main-wrap'>
        { loading ? 
         <div className='center_whole_screen'>
             <BeatLoader  color="#373F51" />
        </div>
        : 
        <>
        <div className='center'>
             <img src='../logo.webp'>
        </img></div>
     <div className='login-box'>
            <div className = "field">
                <label html='username' className='form-label'>Uživatelské jméno</label>
                <input type="text" className='form-control' id="username" 
                placeholder='Zadejte uživatelské jméno' value={username} maxLength={maxLengthInput}
                onChange = {e => setUsername(e.target.value) }/>
            </div>

            <div className = "field">
                <label html ='password' className ='form-label'>Heslo</label>
                <div className='password-input-wrapper'>
                    <input type={showPassword ?"text" : "password"} className = "form-control" id="password" 
                    placeholder='Zadejte heslo'  value={password} maxLength={maxLengthInput}
                    onChange = {e => setPassword(e.target.value)}></input>
                    <div className='password-toggle' onClick={handleTogglePassword}>
                        <img src={ showPassword ? eye : eyeSlash} alt="Ikona oka" ></img>
                    </div>
                </div>
            </div>

            {!isLogin && 
            <div className = "field">
                    <label html='email' className='form-label'>Zadejte email</label>
                    <input type="text" className='form-control' id="email" 
                    placeholder='Zadejte email' value={email} maxLength={maxLengthInput}
                onChange = {e => setEmail(e.target.value)}></input>
        
            </div>
                
                }            
              
                
                {isLogin ? (
                <div className='login_btns'>
                    
                     <button onClick = {loginBtn} className = "dark_btn right">Přihlásit</button> 
                     {isLoginError && <p className='error'>{errorMessage}</p>}
                     <div className='flex-row'>
                        <p>Ještě nemáte účet?</p>
                        <button className = "change_form_btn" onClick = {() => {setLogin(false); setUsername(""); setPassword("");setIsLoginError(false)}}>Registrovat</button> 
                    </div>
                    <p className="decoration_uderline"><Link to="/forgot-password">Zapomněli jste heslo?</Link></p> 
                </div>) : 
                (
                    <div className='login_btns'>
                         <button onClick = {registerBtn} className = "dark_btn right">Registrovat</button> 
                         {isLoginError && <p className='error'>{errorMessage}</p>}
                         <div className='flex-row'>
                            <p>Máte účet?</p>
                            <button className = "change_form_btn" onClick = {() => {setLogin(true),setIsLoginError(false)}}>Přihlásit</button>
                        </div>
                   
                    </div>
                )
                }

            </div>
            </>
            }
            
    </div>
  );
}
 
 export default Login;

