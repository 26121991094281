/************************************************
* File: Player.js                               *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components shows            *
*               player stats.                   *
*************************************************/

import React from 'react'
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import {useState, useEffect , useRef } from 'react'
import swords from '../icons/swords-svgrepo-com.svg';
import choose from '../icons/choose-icon.svg'; 
import dumbbell from '../icons/gym-dumbbell-icon.svg';
import '../css/Player.css';
import text from '../text.json'

import { UserContext } from './UserContext';

function Player(props) {
    const [token] = useState(() => localStorage.getItem("token") || "" );
    const [stats, setStats] = useState()
    const [username ] = useState(() => localStorage.getItem("username") || "" );
    const [id ] = useState(() => localStorage.getItem("id") || "" );
   

    useEffect(() => {
      if (id && !stats) {
       
          APIService.PlayerStats(token, id)
          .then(resp => {
           if( Object.keys(resp).length !== 0 ){
              localStorage.setItem("completed_training", resp.completed_training)
              localStorage.setItem("win_fights", resp.win_fights)
              localStorage.setItem("correct_answer", resp.correct_answer)
              setStats(resp)
           }
         })
           .catch(error => console.log(error))
    }}, [id,stats, token])
   
  return (
    <div className="stats">
       
        <div className="win-stats">
            <img className="icon" src={swords} alt="Ikona zkřížených mečů" />
            <div id="win_fights">{stats?.win_fights ?? "?"}</div>
            <span>{text.home.win_fights}</span>
        </div>
        <div className="win-stats answ">
            <img className="icon" src={choose} alt="Ikona spávné odpovědi" />
            <div id="correct_answer" >{stats?.correct_answer ?? "?"}</div>
            <span>{text.home.correct_answer}</span>
        </div>
        <div className="win-stats train">
            <img className="icon" src={dumbbell} alt="Ikona činky" />
            <div id="completed_training" >{stats?.completed_training ?? "?"}</div>
            <span>{text.home.completed_training}</span>
        </div>

    </div>
  )
}

export default Player