/**************************************************
* File: APIService.js                             *
* Author: Marketa Nedelova (xnedel09)             *
* Creation-date:  03/28/2023                      *
* Description: the file contains a class that ensures communication between the client and server parts. *
**************************************************/

export default class APIService {

    static async callAPI(url, method, body, token){
        try{
            let resp;
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            };

            if (token) {
                headers['Authorization'] = `Token ${token}`;
            }

            const requestOptions = {
                method,
                headers,
            };

            if (body){
                requestOptions.body = JSON.stringify(body);
            }

            resp = await fetch(`https://poznej-rostliny-backend.vercel.app/api/${url}/`,
            requestOptions
            );

            if (resp.status === 204){
                return null;
            }
            else if (resp.ok){ //resp.status === 200
                return await resp.json();
            }
            else{
                console.error(`HTTP error: ${resp.status}`);
            }
        } catch (error){
            console.error(`Error in callAPI: ${error.message}`);
        }
    
    }  

   
    static async LoginUser(body){
      
            const resp = await fetch('https://poznej-rostliny-backend.vercel.app/auth/', {
         'method': 'POST',
         headers: {
             'Content-Type': 'application/json',
             'Access-Control-Allow-Origin': '*',
         },
        
         body: JSON.stringify(body)
             });
         
         return await resp.json();
           
     } 
 
     static async RegisterUser(body){
        
         const resp = await fetch('https://poznej-rostliny-backend.vercel.app/api/register/', {
             'method': 'POST',
             headers: {
                 'Content-Type': 'application/json',
                 'Access-Control-Allow-Origin': '*',
             },
             body: JSON.stringify(body)
         });
 
         return await resp.json();
     }

     static async ForgotPassword(body){
        
        const resp = await fetch('https://poznej-rostliny-backend.vercel.app/api/forgot_password/', {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(body)
        });

        return await resp.json();
    }

    static async ChangePassword(id,body){
        
        const resp = await fetch(`https://poznej-rostliny-backend.vercel.app/api/change_password/${id}/`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(body)
        });

        return await resp.json();
    }

     static async UpdatePlayerPic(token, id, body){
        
        const resp = await fetch(`https://poznej-rostliny-backend.vercel.app/api/update_player_pic/${id}/`, {
            'method': 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(body)
        });

        if (resp.status === 200){
            return await resp.json();
            
        }
        else{
            return null
        }
    }

    static async DeleteWrongAnswer(token, u_id, a_id){
        return await this.callAPI(`delete_wrong_answer/${u_id}/${a_id}`, 'DELETE', null, token);
    }

    static async CreateWrongAnswer(token, body){
        return await this.callAPI('create_wrong_answer', 'POST', body, token);
    }

    static async WrongAnswers(token, id){
        return await this.callAPI(`get_wrong_answers/${id}`, 'GET', null, token);
    }

    static async PlayerPic(token, id){
        return await this.callAPI(`player_pic/${id}`, 'GET', null, token);
    }


    static async TrainingQuestions(token, c_id, cnt){
        return await this.callAPI(`training_questions/${c_id}/${cnt}`, 'GET', null, token);
    }

    static async UserExists(name){
        return await this.callAPI(`user_exists/${name}`, 'GET', null, null);
    }

    static async CreateFightStats(token, body){
        return await this.callAPI('create_fight_stats', 'POST', body, token);
    } 

    static async Leaderboard(token){
        return await this.callAPI('leaderboard', 'GET', null , token);
    }

    static async UpdateFightStats(token, u1_id, u2_id, body ){
        return await this.callAPI(`update_fight_stats/${u1_id}/${u2_id}`, 'PUT', body , token);
    }

    static async FightStats(token, u1_id, u2_id ){
        return await this.callAPI(`get_fight_stats/${u1_id}/${u2_id}`, 'GET', null , token);
    }

    static async FightNotice(token, u_id){
        return await this.callAPI(`get_notice/${u_id}`, 'GET', null , token);
    }

    static async FightQuestionsForRival(token, fk){
        return await this.callAPI(`questions_for_rival/${fk}`, 'GET', null , token);
    }

    static async Username(token, id){
        return await this.callAPI(`username/${id}`, 'GET', null , token);
    }

    static async GetFight(token, u_id){
        return await this.callAPI(`get_fight/${u_id}`, 'GET', null , token);
    }

    static async UpdateFight(token, f_id, body){
        return await this.callAPI(`update_fight/${f_id}`, 'PUT', body, token);
    }

    static async CreateFightQuestions(token, c_id, f_id){
        return await this.callAPI(`fight_questions/${c_id}/${f_id}`, 'GET', null, token);
    }

    static async RandomRival(token){
        return await this.callAPI('random_rival', 'GET', null, token);
    }

    static async CreateFight(token, body){
        return await this.callAPI('create_fight', 'POST', body, token);
    } 

    static async DeleteFight(token, pk){
        return await this.callAPI(`delete_fight/${pk}`, 'DELETE', null, token);
    }

    static async RivalSearch(token, name){
        return await this.callAPI(`user_search/${name}`, 'GET', null, token);
    }

    static async BasicCategories(token){
        return await this.callAPI('basic_categories', 'GET', null, token);
    }

    static async ExtendedCategories(token){
        return await this.callAPI('extended_categories', 'GET', null, token);
    }

    static async PlantList(token,pk){
        return await this.callAPI(`plants/${pk}`, 'GET', null, token);
    }
    

    static async RandomPlantList(token,pk,cnt){
        return await this.callAPI(`random/${pk}/${cnt}`, 'GET', null, token);
    }

    // GET information about concrete category (name, description)
    static async CategoryDetail(token,pk){
        return await this.callAPI(`category/${pk}`, 'GET', null, token);
    }

     // GET an array containing all categories (name, id)
    static async Categories(token){
        return await this.callAPI('categories', 'GET', null, token);
    }

    static async PlantDetail(token,pk){
        return await this.callAPI(`plant_detail/${pk}`, 'GET', null, token);
    }

    static async PlantPhotos(token,pk){
        return await this.callAPI(`plant_photos/${pk}`, 'GET', null, token);
    }

    static async UserId(token, name){
        return await this.callAPI(`user_id/${name}`, 'GET', null, token);
    }

    static async PlayerStats(token, id){
        return await this.callAPI(`players/${id}`, 'GET', null, token);
    }

    static async UpdatePlayerStats(token, id, body ){
        return await this.callAPI(`players/${id}`, 'PUT', body, token);
    }
}