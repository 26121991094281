import './App.css';
import {useState, useEffect, createContext } from 'react'
import {useCookies, removeCookie } from 'react-cookie';
import {BrowserRouter as Router, Route, Routes, useNavigate, useSubmit } from 'react-router-dom';
import { UserContextProvider } from './components/UserContext';
import Study from './components/Study';
import {Link} from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import PrivateRoute from "./utils/PrivateRoute"
import ErrorPage from './components/ErrorPage';
import Training from './components/Training';
import Fight from './components/Fight';
import Leaderboards from './components/Leaderboards';
import PlantList from './components/PlantList';
import PlantDetail from './components/PlantDetail';
import TrainingQuiz from './components/TrainingQuiz';
import TrainingCategory  from './components/TrainingCategory';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';

function App() {

const [token, setToken, removeToken] = useCookies(['mytoken'])
const [userName, setUserName] = useState("");


  return (
    <div className="App">
      
      
    <UserContextProvider>
     <Routes> 
     <Route element={<PrivateRoute />}>
            <Route  path = "/" element = {<Home />} exact/>
            <Route  path = "/study" element = {<Study />}/>
            <Route  path = "/training" element = {<Training />}/>
            <Route  path = "/fight" element = {<Fight />}/>
            <Route  path = "/leaderboards" element = {<Leaderboards />}/>

            <Route  path = "/plant_list" element = {<PlantList />}/>
            <Route  path = "/plant_list/:id" element = {<PlantDetail />}/>

            <Route  path = "/training_category" element = {<TrainingCategory />}/>
            <Route  path = "/quiz" element = {<TrainingQuiz />}/> 
     </Route>
        <Route path = "/login" element = {<Login />}/>
        <Route path = "/forgot-password" element = {<ForgotPassword />}/>
        <Route path = "/change-password/:id" element = {<ChangePassword />}/>
        <Route path = "*" element = {< ErrorPage/>}/>
        </Routes>
     {/* { token.mytoken && <Navigation/> } */} 
      </UserContextProvider>
    </div>
  );
} 

export default App;
