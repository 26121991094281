import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <BrowserRouter>
     <App />
     </BrowserRouter>
);


if ('serviceWorker' in navigator) {
  try {
    navigator.serviceWorker.register('./sw.js')
      .then((registration) => {
        console.log('Service worker registered:', registration);
      })
      .catch((error) => {
        console.error('Service worker registration failed:', error);
      });
  } catch (error) {
    console.error('Error during service worker registration:', error);
  }
} else {
  console.log('Service worker is not supported in this browser.');
}

