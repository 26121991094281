/************************************************
* File: PlantList.js                            *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays names   *
*               of plants of concrete category. *
*************************************************/

import React, {useParams, useEffect, useState} from 'react'
import arrow from '../icons/arrow-thin-left-icon.svg';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import PlantDetail  from './PlantDetail';
import "../css/common.css"
import "../css/PlantList.css"
import BeatLoader from "react-spinners/BeatLoader";
import Navigation from './Navigation' 

function PlantList(props) {
  const [token] = useState(() => localStorage.getItem("token") || "" );
   const { search } = useLocation();
   const params = new URLSearchParams(search);
  const name = params.get('name');
  const id = params.get('id');
  const [plants, setPlants] = useState(null)
  const [showPlantDetail, setShowPlantDetail] = useState(false)
  const [plantId, setPlantId] = useState(null);

  useEffect(() => {
    APIService.PlantList(token,id )
    .then(resp => setPlants(resp))
     .catch(error => console.log(error))
   }, [])

  const handlePlantDetail = (id) => {
    setPlantId(id);
    setShowPlantDetail(true);
    }

  return ( 
    <div className="main-wrap">
      {showPlantDetail ?
        (
       <div className='flex-wrap flex-start'><button className='white_back' onClick={()=>{setShowPlantDetail(false)}}>
      
       <img className="nav-icon" src={arrow} alt="Ikona šipky vlevo" />
       
       </button></div>) : 
      
     ( <div className='flex-wrap'>  
      <Link to="/study">
      <div className="icon">
      <img className="nav-icon" src={arrow} alt="Ikona šipky vlevo" />
      </div>
      </Link>
      <h1 className='item'>{name}</h1>  
      </div>  )
    
    }
    
   
    {showPlantDetail ? 
    <div>
      <PlantDetail plant_id={plantId}></PlantDetail>
    </div>
    : 
    <div className='flex-wrap'>
    {plants ? plants.map(plant => (
      <button className="white_back" key={plant.id} onClick={() => handlePlantDetail(plant.id) }>
        <div className='common_btn wide_btn' key={plant.id}>
        <h3>{plant.cz_name}</h3>
        </div>
      </button>
   )) :
   <div className='center'>
        <BeatLoader  color="#373F51" />
   </div>
   }
   </div>
   
    }
      <Navigation page="study"/>
    </div>
  )
}

export default PlantList