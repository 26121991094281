/************************************************
* File: TrainingCategory.js                     *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays         *
*              category detail.                 *
*************************************************/

import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import {useLocation } from 'react-router-dom';
import {useCookies} from 'react-cookie';

import APIService from '../APIService';
import arrow from '../icons/arrow-thin-left-icon.svg';
import "../css/common.css"
import "../css/TrainingCategory.css"
import BeatLoader from "react-spinners/BeatLoader";

function TrainingCategory() {
  const [token] = useState(() => localStorage.getItem("token") || "" );
  const { search } = useLocation();
  const params = new URLSearchParams(search);
 const id = params.get('id');
 const [cnt,setCnt] = useState(7);
 const [category, setCategory] = useState(null)
 const [loading, setLoading] = useState(false)
 const [description, setDescription] = useState(null)


 useEffect(() => {
  setLoading(true)
  APIService.CategoryDetail(token,id)
  .then(resp => {
    let tmp = resp['description'].split("|")
    setDescription(tmp)
    setCategory(resp)
    setLoading(false)
  })
  .catch(error => console.log(error))
  
 }, []) 

 const handleInputChange = (event) => {
    setCnt(event.target.value);
}

const handleDecrement = () => {
  if (cnt > 1){
    setCnt(cnt - 1);
  }
 
}

const handleIcrement = () => {
  if (cnt < 50){
    setCnt(cnt + 1);
  }
}

  return (
    <div className='main-wrap'>

    
      { loading ? 
       <div className='center_whole_screen'>
       <BeatLoader  color="#373F51" />
    </div>
    : 
    <>
      <div className='flex-wrap '>
      <div className='flex-w'>
        <Link to={`/training`}>
          <div className="icon">
          <img className="nav-icon" src={arrow} alt="Ikona šipky vlevo" />
        </div>
        </Link>
        </div>
        <div>
        { category && <h1 className='PageName'>{category['name']}</h1>}
       
       <ul className='w85'>
        {description && description.map((text, index) => (
       <li key={index} >{text}</li>
        )) }
        </ul>
        </div>
        <div className='cnt_panel'>
          <h1>Počet otázek: </h1>
          <button className='common_btn dark_b' onClick = {handleDecrement}>-</button>
          <input type='number' id="cnt" onChange={handleInputChange} value={cnt}></input>
          <button  className='common_btn dark_b' onClick = {handleIcrement}>+</button>
          
        </div>
        <div >
          <Link to={`/quiz?cnt=${cnt}&c_id=${id}`}>
        <button  className='common_btn start' >SPUSTIT</button>
        </Link>
        </div>
      
    </div> 
  </>
            }
    </div>
  )
}

export default TrainingCategory