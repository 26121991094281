/************************************************
* File: PlantDetail.js                          *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays         *
*               informations about plant.       *
*************************************************/

import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import { useLocation , useParams } from 'react-router-dom';
import {useCookies} from 'react-cookie';

import APIService from '../APIService';
import arrow from '../icons/arrow-thin-left-icon.svg';
import flowerFill from '../icons/flower-fill.svg';
import flowerOutline from '../icons/flower-outline.svg';
import flowerMissing from '../images/photo_missing.png'
import "../css/PlantDetail.css"
import BeatLoader from "react-spinners/BeatLoader";
import "../css/common.css"

function PlantDetail(props) {
   const [token] = useState(() => localStorage.getItem("token") || "" );
   const { id, plant_id } = useParams();
   const [plant, setPlant] = useState([]);
   const [plantPhotos, setPlantPhotos] = useState([]);
   const [colorsLoaded, setColorsLoaded] = useState(false)
   const [loadingDetail, setLoadingDetail] = useState(false)
   const [loadingPhotos, setLoadingPhotos] = useState(false)

   useEffect(() => {
    setLoadingDetail(true)
    APIService.PlantDetail(token,props.plant_id)
    .then(resp => {
      setPlant(resp)
      setLoadingDetail(false)})
    .catch(error => {
      console.log(error)
      setLoadingDetail(false)})
   }, []) 

   useEffect(() => {
    setLoadingPhotos(true)
    APIService.PlantPhotos(token,props.plant_id)
    .then(resp => {
      setPlantPhotos(resp)
      setLoadingPhotos(false)})
    .catch(error => {
      console.log(error)
      setLoadingPhotos(false)})
   }, []) 

  useEffect(() => {
 
  if (plant.blossom_time ){
  for (let month of plant.blossom_time){
    document.getElementById(`f${month}`).setAttribute("src", `${flowerFill}`);
  }
}

  }, [plant.blossom_time]) ;

  useEffect(() => {
    if (plant.colors_hex && !colorsLoaded){
    for (let hex of plant.colors_hex){
      const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgElement.setAttribute("width","15"); 
      svgElement.setAttribute("height","15");
      svgElement.style.marginRight = "5px";

      const rectElement = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      rectElement.setAttribute("width","15"); 
      rectElement.setAttribute("height","15"); 
      rectElement.setAttribute("stroke-width","1"); 
      rectElement.setAttribute("stroke","rgb(0,0,0)");
      rectElement.setAttribute("fill",`${hex}`);

      svgElement.appendChild(rectElement);
      document.getElementById("plant_color_rect").appendChild(svgElement);
    }

    setColorsLoaded(true);
  }
  
    }, [plant.colors_hex, colorsLoaded]) ;
  
  return (
    <>
    <div className='img-wrap'>
    { loadingPhotos ? 
         <div className='center'>
             <BeatLoader  color="#373F51" />
        </div>
        : 
    <img id="flower_photo" alt="Fotografie rostliny" src={ plantPhotos.length > 0 ? plantPhotos[0]['pic_url'] : flowerMissing}/>
    }
    </div> 
    { loadingDetail ? 
         <div className='center'>
             <BeatLoader  color="#373F51" />
        </div>
        : 
        <>
        <h1 id='cz_name'>{plant.cz_name}</h1>
        <h3 id='lat_name'>({plant.lat_name})</h3>
        
        <div className='plant_info'>
          <div>
          <span className='bold_txt'>ČELEĎ: </span>
          <span> {plant.family}</span>
          </div>

          <div>
          <span className='bold_txt'>SVĚTLO:
          </span>
           <span> {plant.habitats && plant.habitats.join(", ")}</span>
          </div>

          <div>
          <span className='bold_txt'>PŮDA: </span> 
          <span>{plant.soils && plant.soils.join(", ") }
          </span>
          </div>

        

          <div className='inline-flex'>
          <span className='bold_txt'>BARVA KVĚTU: 
          </span> <span id='plant_color_rect'>   
          </span>
          </div>

          <div>
          <span className='bold_txt'>DOBA KVĚTU:
          </span> <span>  <table>
              <thead>
              <tr>
           
          <th><img  id="f1"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f2"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f3"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f4"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f5"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f6"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f7"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f8"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f9"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f10"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f11"  width="20" height="20" src={flowerOutline} /></th>
          <th><img  id="f12"  width="20" height="20" src={flowerOutline} /></th>
           </tr>
              <tr>
                <td><h3>1</h3></td>
                <td><h3>2</h3></td>
                <td><h3>3</h3></td>
                <td><h3>4</h3></td>
                <td><h3>5</h3></td>
                <td><h3>6</h3></td>
                <td><h3>7</h3></td>
                <td><h3>8</h3></td>
                <td><h3>9</h3></td>
                <td><h3>10</h3></td>
                <td><h3>11</h3></td>
                <td><h3>12</h3></td>
              </tr>
              </thead>
            </table></span>
          </div>
          
          <div>
          <span className='bold_txt'>OSTATNÍ:
          </span> <span> {plant.others}</span>
          </div>
          
        </div>
        </>
            }
    
    </>


  )


}

export default PlantDetail
