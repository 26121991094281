/************************************************
* File: Training.js                             *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays         *
*              category name.                   *
*************************************************/

import React, {useState, useEffect, useRef } from 'react'
import CategoryList from './CategoryList'
import APIService from '../APIService'
import {useCookies} from 'react-cookie';
import Navigation from './Navigation'
import "../css/common.css"
import BeatLoader from "react-spinners/BeatLoader";

function Training() {
  const [token] = useState(() => localStorage.getItem("token") || "" );
  const [categories, setCategories] = useState(null)
  const [basicCategories, setBasicCategories] = useState([])
  const [extendedCategories, setExtendedCategories] = useState([])
  const [isBasic, setBasic] = useState(true)
  const [study] = useState(false)
  const prevTokenRef = useRef(null)

  useEffect(() => {
    if(prevTokenRef.current !== token){
      APIService.BasicCategories(token)
      .then(resp => {
        setCategories(resp)
        setBasicCategories(resp)
        })
        .catch(error => console.log(error))
        prevTokenRef.current = token
  }
  }, [token])

  const ExtendedBtn = () => {
    setBasic(false);
    if (extendedCategories.length > 0){
      setCategories(extendedCategories)
    }
    else{
      APIService.ExtendedCategories(token)
      .then(resp => {
        setCategories(resp)
        setExtendedCategories(resp)
      })
       .catch(error => console.log(error))
    }
}

const BasicBtn = () => {
   setBasic(true);
   setCategories(basicCategories)

}

  return (
    <div className='main-wrap'>  
        <h1 className='PageName'>Trénink</h1>
        <div className = "w85">
        {isBasic ? <button onClick={ExtendedBtn} className='change_category_btn'>Rozšíření</button>
      : <button onClick={BasicBtn} className='change_category_btn'>Základní</button> }
      </div>
      
    { categories ? <CategoryList categories = {categories} study = {study}/>
     : 
      <div className='center'>
         <BeatLoader  color="#373F51" />
      </div>}
    <Navigation page="training"/>
    </div>
  )
}

export default Training