/************************************************
* File: Navigation.js                           *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this components displays         *
*               navigation of app.              *
*************************************************/

import React, { useCallback, useState} from "react";
import {Link} from 'react-router-dom';

import swords from '../icons/swords-svgrepo-com.svg';
import trophy from '../icons/champion-winner-trophy-icon.svg';
import dumbbell from '../icons/gym-dumbbell-icon.svg';
import sportsman from '../icons/sportsman-icon.svg';
import book from '../icons/book-line-icon.svg';


import '../css/navigation.css';


const Navigation = (props) => {
    const [activePage] = useState(props.page);

 // const handlePageChange = (page) => {
   // setActivePage(page);
  //};

   
    return (
    <div className="navigation-div">
        <ul className="nav-ul">
    <li className="nav-item">
    <Link id="link-study" to="/study" className={activePage === 'study' ? 'dark' : ''} 
    >
                <img className={activePage === 'study' ? 'white_i' : 'nav-icon'} src={book} alt="Ikona knihy" />
          
            <span className={activePage === 'study' ? ' name white' : 'name'}>Studium</span>
    </Link>
    </li>
    <li>
        <Link to="/training" className={activePage === 'training' ? 'dark' : ''}>
            
                <img className={activePage === 'training' ? 'white_i' : 'nav-icon'} src={dumbbell} alt="Ikona činky" />
           
            <span className={activePage === 'training' ? ' name white' : 'name'} >Trénink</span>
        </Link>
    </li>
    <li>
    <Link to="/" className={activePage === 'profile' ? 'dark' : ''}>
          
                <img className={activePage === 'profile' ? 'white_i' : 'nav-icon'} src={sportsman} alt="Ikona hlavy člověka" />
         
            <span className={activePage === 'profile' ? ' name white' : 'name'}>Profil</span>
    </Link>
    </li>
    <li>
        <Link to="/fight" className={activePage === 'fight' ? 'dark' : ''}>
            
                <img className={activePage === 'fight' ? 'white_i' : 'nav-icon'} src={swords} alt="Ikona zkřížené meče" />
        
            <span  className={activePage === 'fight' ? ' name white' : 'name'}>Souboj</span>
            </Link>
    </li>
    <li>
        <Link to="/leaderboards" className={activePage === 'leaderboards' ? 'dark' : ''}>
           
                <img className={activePage === 'leaderboards' ? 'white_i' : 'nav-icon'} src={trophy} alt="Ikona poháru" />
          
            <span className={activePage === 'leaderboards' ? ' name white' : 'name'}>Žebříčky</span>
            </Link>
    </li>
</ul>

    </div>)
 
};

export default Navigation;
