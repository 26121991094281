/************************************************
* File: ForgotPassword.js                       *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  09/17/2023                    *
* Description: this components helps with       *
*              forgot password                  *
*************************************************/

import React, {useState} from 'react'
import arrow from '../icons/arrow-thin-left-icon.svg';
import text from '../text.json';
import '../css/common.css'
import APIService from '../APIService';
import { Link } from 'react-router-dom';

function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [maxLengthInput] = useState(40)
    const [Message, setMessage] = useState('');
    const [msgType, setMsgType] = useState('error')

    const submitForm = () => {
        APIService.ForgotPassword({"email": email})
            .then(resp => {
                setMessage(resp.msg)
                if(resp.bool){
                    setMsgType('info')
                }
                })
            .catch(error => console.log(error))

        setEmail('')
        }

  return (
    <div className='main-wrap'>
        <div className='flex-wrap flex-start'>
            <Link to="/login">
                <img className="nav-icon" src={arrow} alt="Ikona šipky vlevo" />
            </Link>
        </div>
         <h1 className='item'>{text['forgot-psw'].heading}</h1>
         <div className='forgot-psw-box'>
            <div className = "field">
                        <label html='email' className='form-label'>{text['forgot-psw'].email}</label>
                        <input type="email" className='form-control' id="email" 
                        placeholder='Zadejte email' value={email} maxLength={maxLengthInput}
                    onChange = {e => setEmail(e.target.value)}></input>
                    
            </div>
            {Message && <p className={msgType}>{Message}</p>}
            <button type="submit" onClick={submitForm} className = "dark_btn right">{text['forgot-psw']['send-btn']}</button> 
        </div>
        
    </div>
   
  )
}

export default ForgotPassword