/************************************************
* File: CategoryList.js                         *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  04/03/2023                    *
* Description: this component shows names of    *
*              basic/extended categories.       *
*************************************************/

import React ,{useState} from 'react'
import {Link} from 'react-router-dom';
import APIService from '../APIService';
import {useCookies} from 'react-cookie';
import "../css/common.css"

function CategoryList(props) {
    const [token, setToken] = useState(() => localStorage.getItem("token") || "" );

  return (
    <div className='w85'>
        {props.categories && props.categories.map((category, index) => {
           let background = "blue";
         /* if(parseInt(category.type) === 0)
            { background =
            index % 3 === 0 ? "dark_blue" :
            index % 3 === 1 ? "blue" :
            "pink";
          }
          else{
            background =
            index % 3 === 0 ? "green" :
            index % 3 === 1 ? "dark_pink" :
            "blue";
          }*/

        return (<Link key={category.id} to={ props.study ? `/plant_list/?id=${category.id}&name=${category.name}` : `/training_category/?id=${category.id}`}>
          <div className={`common_btn ${background}`} key={category.id}>
          <h3>{category.name}</h3>
          </div>
         </Link>
     )})} 
   
    </div>
  )
}

export default CategoryList