/************************************************
* File: PrivateRoute.js                         *
* Author: Marketa Nedelova (xnedel09)           *
* Creation-date:  03/22/2023                    *
* Description: this file is for private routes. *
*************************************************/

import {Outlet, Navigate } from 'react-router-dom'
import {useCookies} from 'react-cookie';

const PrivateRoute = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['mytoken'])
    const handleLogout = () => {
        removeCookie('mytoken');
      };

      return (
        <>
          {cookies.mytoken && cookies.mytoken !== "undefined" ?  <Outlet /> : <Navigate to="/login" />}
        </>
      );
};


export default PrivateRoute;

